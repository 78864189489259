//
//  Header for issue (iframe) pages: flipbook, pdf-es, pdf-ds
//  taken from components/logoHeader from money-times-2021 (deprecated)
//

import React from 'react'
import {Link} from 'gatsby'
import logo from '../assets/svg/money-times-logo.svg'

export default function Header() {
  return (
  <header className="text-center text-[10px] box-content">
    <Link to="/" className="block mx-auto w-[max-content] box-content"><
      img alt="Money/Times logo" src={logo} className="h-[2.8em] p-[1em] z-10 curspor-pointer box-content"/>
    </Link>
  </header>
  )
}
